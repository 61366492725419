/* color */

$black: #000;
$white: #FFF;
$gray: #555555;
$gray2: #777777;
$gray3: #F8F9FB;
$gray4: #B6B4B4;
$gray5: #A8A8A8;
$gray6: #F5F5F5;
$dark: #3C4B5A;
$green: #13B954;
$yellow: #FEBE34;
$blue: #4676EE;
$principal:#1beae4;
$secundary: #00838C;
$complementary: #FD5C5C;
$bor-gray: 1px solid #A8A8A8;
$bor-green: 1px solid #00CCCB;
$text-color: #565656;
$shadow:#d6e6ed;