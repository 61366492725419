/*login*/
.bd-login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 2;
    overflow-y: auto;

    @include md{
        position: inherit;
    }
}
.login{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
    position: relative;

    @include md{
        grid-template-columns: 1fr;
    }

    .logo-header{

        @include md{
            position: absolute;
            top: 20px;
            width: 100%;

            img{
                display: block;
                margin: 0 auto;
            }
        }
        
        img{
            display: block;

            @include md{
                height: 45px;
            }
            @include xxs{
                height: 34px;
            }
        }
    }

    .intro-login{
        background: $gray3;
        overflow: hidden;
        position: relative;
        justify-content: center;

        @include md{
            height: 250px;
            box-sizing: border-box;
            margin-top: 80px;
        }
        @include sm{
            height: 200px;
        }
        @include s{
            height: auto;
        }
        @include xxs{
            margin-top: 60px;
        }

        h2{
            font-size: rem(36px);
            font-weight: 400;
            margin: rem(50px 0 0 60px);
            color: $principal;
            z-index: 2;
            position: relative;

            @include md{
                font-size: rem(30px);
            }
            @include sm{
                font-size: rem(23px);
            }
            @include s{
                font-size: rem(20px);
                margin: rem(60px 30px);
            }
            @include xxs{
                font-size: rem(16px);
                margin: rem(30px 20px);
            }
        }

        .path{
            position: absolute;
            width: 110%;
            left: -5%;
            bottom: 15%;
            z-index: 0;
            align-self: center;

            @include md{
                left: auto;
                width: 90%;
                bottom: -60px;
                right: -90px;
            }
            @include s{
                width: 80%;
            }
            @include xxs{
                right: -5%;
                left: auto;
            }
            @include iplus{
                right: -50px;
                width: 70%;
                bottom: -20px;
            }
        }
        .img-empleadora{
            z-index: 1;
            position: absolute;
            bottom: 0;
            left: calc(50% - 295px);
            display: block;
            width: 590px;

            @include mediaAlto(950px){
                width: 500px;
                left: calc(50% - 250px);
            }
            @include mediaAlto(850px){
                width: 400px;
                left: calc(50% - 200px);
            }
            @include mediaAlto(700px){
                width: 350px;
                left: calc(50% - 175px);
            }
            @include xl{
               width: 500px;
                left: calc(50% - 250px); 
            }
            @include lg{
                width: 400px;
                left: calc(50% - 200px);
            }
            @include md{
                width: 300px;
                left: auto;
                right: 50px;
                bottom: -100px;
            }
            @include sm{
                width: 170px;
                bottom: -30px;
            }
            @include s{
                width: 120px;
                bottom: 0;
                right: 30px;
            }
            @include xxs{
                width: 100px;
                bottom: -30px;
            }
        }
    }
    .container-login{
        overflow-y: scroll;

        @include md{
            overflow-y: inherit;
        }
    }
    .input-pass{
        background: $white;

        input{
            background: none;
            border: none;
        }
        .btn{
            color: $gray4;
            font-size: rem(16px);
            padding: rem(11px 20px);

            &:hover{
                color: $principal;
            }
        }
    }
    .box-social{
        max-width: 320px;


        .btn{
            border: 1px solid #E8E8E8;
            font-size: rem(16px);

            .icon-facebook{
                color: $blue;
            }

            img{
                display: inline-block;
                width: 16px;
            }
        }
    }
}