@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?mzphjr');
  src:  url('../fonts/icomoon.eot?mzphjr#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?mzphjr') format('truetype'),
    url('../fonts/icomoon.woff?mzphjr') format('woff'),
    url('../fonts/icomoon.svg?mzphjr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
 
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




.icon-eye-block:before {
  content: "\e900";
}
.icon-access:before {
  content: "\e901";
}
.icon-account:before {
  content: "\e902";
}
.icon-admiration:before {
  content: "\e903";
}
.icon-afiliacion:before {
  content: "\e904";
}
.icon-ahorro:before {
  content: "\e905";
}
.icon-amplify:before {
  content: "\e906";
}
.icon-archive:before {
  content: "\e907";
}
.icon-arrow-bottom:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-arrow-lg-bottom:before {
  content: "\e90a";
}
.icon-arrow-lg-left:before {
  content: "\e90b";
}
.icon-arrow-lg-right:before {
  content: "\e90c";
}
.icon-arrow-lg-top:before {
  content: "\e90d";
}
.icon-arrow-numeric:before {
  content: "\e90e";
}
.icon-arrow-right:before {
  content: "\e90f";
}
.icon-arrow-top:before {
  content: "\e910";
}
.icon-attention:before {
  content: "\e911";
}
.icon-ausencias:before {
  content: "\e912";
}
.icon-bell:before {
  content: "\e913";
}
.icon-bonificaciones:before {
  content: "\e914";
}
.icon-bubble:before {
  content: "\e915";
}
.icon-calendar:before {
  content: "\e916";
}
.icon-call:before {
  content: "\e917";
}
.icon-camera:before {
  content: "\e918";
}
.icon-card-arl:before {
  content: "\e919";
}
.icon-card-caja:before {
  content: "\e91a";
}
.icon-card-cedula-retiro:before {
  content: "\e91b";
}
.icon-card-cedula-tiro:before {
  content: "\e91c";
}
.icon-card-eps:before {
  content: "\e91d";
}
.icon-card-rut:before {
  content: "\e91e";
}
.icon-card:before {
  content: "\e91f";
}
.icon-chair:before {
  content: "\e920";
}
.icon-chat:before {
  content: "\e921";
}
.icon-check-full:before {
  content: "\e922";
}
.icon-check:before {
  content: "\e923";
}
.icon-clock:before {
  content: "\e924";
}
.icon-close-full:before {
  content: "\e925";
}
.icon-close:before {
  content: "\e926";
}
.icon-contract:before {
  content: "\e927";
}
.icon-cure:before {
  content: "\e928";
}
.icon-des-nomina:before {
  content: "\e929";
}
.icon-dias-trabajados:before {
  content: "\e92a";
}
.icon-doc-close:before {
  content: "\e92b";
}
.icon-doc-down:before {
  content: "\e92c";
}
.icon-doc-edit:before {
  content: "\e92d";
}
.icon-doc-novedad:before {
  content: "\e92e";
}
.icon-doc:before {
  content: "\e92f";
}
.icon-down:before {
  content: "\e930";
}
.icon-edit:before {
  content: "\e931";
}
.icon-eye:before {
  content: "\e932";
}
.icon-filter:before {
  content: "\e933";
}
.icon-firma:before {
  content: "\e934";
}
.icon-flag:before {
  content: "\e935";
}
.icon-fly:before {
  content: "\e936";
}
.icon-folder-h:before {
  content: "\e937";
}
.icon-folder:before {
  content: "\e938";
}
.icon-h-extra:before {
  content: "\e939";
}
.icon-hammer:before {
  content: "\e93a";
}
.icon-health:before {
  content: "\e93b";
}
.icon-heart-two:before {
  content: "\e93c";
}
.icon-heart:before {
  content: "\e93d";
}
.icon-house:before {
  content: "\e93e";
}
.icon-Incapacidades:before {
  content: "\e93f";
}
.icon-interrogation:before {
  content: "\e940";
}
.icon-licencias:before {
  content: "\e941";
}
.icon-mail:before {
  content: "\e942";
}
.icon-money-bag:before {
  content: "\e943";
}
.icon-money:before {
  content: "\e944";
}
.icon-more:before {
  content: "\e945";
}
.icon-off:before {
  content: "\e946";
}
.icon-payroll:before {
  content: "\e947";
}
.icon-Permisos:before {
  content: "\e948";
}
.icon-planilla:before {
  content: "\e949";
}
.icon-prestamos:before {
  content: "\e94a";
}
.icon-promo:before {
  content: "\e94b";
}
.icon-rec:before {
  content: "\e94c";
}
.icon-referred:before {
  content: "\e94d";
}
.icon-refresh:before {
  content: "\e94e";
}
.icon-screen:before {
  content: "\e94f";
}
.icon-search:before {
  content: "\e950";
}
.icon-security:before {
  content: "\e951";
}
.icon-send:before {
  content: "\e952";
}
.icon-share:before {
  content: "\e953";
}
.icon-shirt:before {
  content: "\e954";
}
.icon-star-spiral:before {
  content: "\e955";
}
.icon-star:before {
  content: "\e956";
}
.icon-store:before {
  content: "\e957";
}
.icon-task:before {
  content: "\e958";
}
.icon-ticket:before {
  content: "\e959";
}
.icon-trash:before {
  content: "\e95a";
}
.icon-up:before {
  content: "\e95b";
}
.icon-user-close:before {
  content: "\e95c";
}
.icon-user:before {
  content: "\e95d";
}
.icon-users:before {
  content: "\e95e";
}
.icon-vacaciones:before {
  content: "\e95f";
}
.icon-home:before {
  content: "\e960";
}

.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-checked2:before {
  content: "\ea55";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-google:before {
  content: "\ea88";
}
.icon-google-drive:before {
  content: "\ea8f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-arrow-bottom-active-e:before {
  content: "\e908";
}
