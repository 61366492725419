.navbar .container-fluid{
    display:inline-block !important;
}
.navbar-brand{
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    align-items: center !important;
    display: inline-flex !important;
    justify-content: center !important;
    padding: 0 10px !important;

    @include media(768px){
        height: auto !important;
    }
}
.navbar-brand img{
    width: 100% !important;
    max-width: 175px !important;
    height: auto !important;
}

/*header*/
.sy-head {
    min-height: 6rem !important;
    background: $gray3 !important;
   padding: 0 !important;

    @include sm{
        min-height: auto !important;
        background:none !important;
        box-shadow: 0 2px 5px $shadow  !important;
       
    }
    .navbar-nav-scroll{
        width: calc(100% - 80px)  !important;

        @include sm{
            width: calc(100% - 60px)  !important;
            height: auto  !important;
        }

        h1{
            font-size: 22px !important;
            font-weight: 300 !important;
            margin: 0 0 0 4% !important;
            color: $principal !important;
    
            @include media(991.98px){
                max-width: 96% !important;
                overflow: hidden !important;
                font-size: 20px !important;
            }
            @include sm{
                height: auto !important;
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                white-space: nowrap !important;
            }
            @include s{
                font-size: rem(18px) !important;
            }
            @include xxs{
                font-size: rem(16px) !important;
            }
        }
    }
    .sy-logo{
        background: #F8F9FB !important;
        min-height: 6rem !important;
        z-index: 2 !important;
        padding: 0 15px 0 0 !important;

        @include media(768px){
        
            display: none !important;
        }

        .sy-logoc{
            height: 100% !important;
            box-shadow: 0px 6px 0px #FFFFFF, 1px 0 8px $shadow !important;
            padding: 0 0 0 10px !important;
            background: #FFFFFF !important;

            @include media(768px){
                height: auto !important;
                padding: 0 !important;
            }

            @include sm{
                box-shadow:none !important;
                background: none !important;
            }
        }
    }
    .sy-bar{
        background: #F8F9FB !important;
        display: inline-flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;

        @include sm{
            justify-content:flex-start !important;
            background: #FFFFFF !important;
            padding: 20px 10px !important;
            width: 100%
        }
        .btn-back{
            font-size: rem(16px) !important;
            color: $principal !important;
            text-decoration: none !important;
            display: inline-flex !important;
            align-items: center !important;

            &:hover{
                color: $secundary !important;
            }
        }
    }
    .navbar-nav{
        -ms-flex-direction:row !important;
        flex-direction:row !important;

        .dropdown-toggle {
            i{
                width: 45px !important;
                height: 45px !important;
                background: #FFFFFF !important;
                font-size: 24px !important;
                padding: 10px !important;
                border-radius: 50px !important;
                color: #565656 !important;
                &:hover{
                    box-shadow: 0 2px 5px $shadow !important;
                    color: $principal !important
                }
                @include sm{
                    background:#F8F9FB !important;
                }
            }
        }
        .nav-link {
            padding-right: .5rem !important;
            padding-left: .5rem !important;

            &:hover{
                color: $principal !important;
                img{
                    transform: scale(1.1) !important;
                }
            }
            &.active{
                color: $principal !important;
                img{
                    transform: scale(1.1) !important;
                }
            }

            img{
                transition: 0.5s !important;
            }
        }
        .dropdown-toggle:after{
            display:none !important;
        }
    }
    .dropdown-menu {
        font-size: .875rem !important;
        background: #FBFBFB !important;
        padding: 0 !important;
        border: none !important;
        border-radius: 0.6rem !important;
        box-shadow: 0 2px 5px $shadow !important;
        position: absolute !important;

        .drop-cont-list{
            padding: 15px !important;
        }
        a{
            color: $text-color !important;
            font-weight: 300 !important;

            &:hover{
                color: $principal !important;
            }

            img{
                margin: 0 5px 0 0 !important;
            }
        }
        .dropdown-close{
            background: #FFFFFF !important;
            border-top: 1px solid #e9ecef !important;
            padding: 15px !important;
            border-radius: 0 0 0.6rem 0.6rem !important;

            a{
                border:1px solid #e9ecef !important;
                border-radius: 100px !important;
                text-align: center !important;
                color: $text-color !important;
                font-weight: 300 !important;
            }
        }
        .dropdown-item {
            &.active{
                background: none !important;
                color: $principal !important;
            }
            i{
                font-size: 14px !important;
                margin: 0 5px 0 0 !important;
            }
        }
    }
}
@media (min-width:768px) {
  @supports ((position: -webkit-sticky) or (position:sticky)) {
    .sy-head {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0 !important;
        z-index: 1071 !important;
        padding: 0 !important;
        
    }
  }
}



/*menú lateral*/

.sy-nav{
    -ms-flex-order: 0 !important;
    order: 0 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 6rem !important; 
    z-index: 1000 !important;
    height: calc(100vh - 6rem) !important;
    padding: 0 15px 0 0 !important;
    border:0 !important;
    background: #FFFFFF !important;
    
    &:before{
        width: 100% !important;
        height:30px !important;
        background:#FFF !important;
        top: -20px !important;
        content: "" !important ;
        position: absolute !important;
        z-index: 1000 !important;
        filter: blur(4px) !important;
        -webkit-filter: blur(4px) !important;
    }
    
    .sy-navc{
        width: 100% !important;
        height: 100% !important;
        box-shadow: 1px 0 8px $shadow !important;
        position: relative !important;
        z-index: 1001 !important;
    }
    nav {
        padding: 2.8rem 0 4rem 15px !important;
        margin-right: -15px !important;
        margin-left: -15px !important;
        z-index: 2 !important;
        position: relative !important;
        max-height: calc(100vh - 8rem) !important;
        overflow-y: auto !important;
        display: block !important;
        
        @include sm{
            width: 100% !important;
            height: 100% !important;
            padding:10px 0 0 0 !important;
            margin: 0 !important;
            margin-top: -15px !important;
            display: table !important;
        }
    }
    @include sm{
        width: 100% !important;
        height: auto !important;
        max-width: none !important;
        position: fixed !important;
        left: 0 !important;
        top: auto !important;
        bottom: 0 !important;
        z-index: 1010 !important;
        padding: 15px 0 0 0 !important;
    }
}
/*@media (min-width:768px) {
    .sy-nav {
        border:0;
    }
    @supports ((position:-webkit-sticky) or (position:sticky)) {
    .sy-nav {
        position: -webkit-sticky;
        position: sticky;
        top: 6rem;
        z-index: 1000;
        height: calc(100vh - 6rem);
        padding: 0 15px 0 0;
    }
  }
}
@media (min-width:1200px) {
  .bd-sidebar {
    -ms-flex: 0 1 320px;
    flex: 0 1 320px
  }
}
@media (min-width:768px) {
    .sy-nav nav {
        display: block !important;
    }
}*/

.sy-item{
    position: relative !important;
    z-index: 3 !important;

    &.active {
        position: relative !important;
        z-index: 3 !important;

        &> .sy-link {
            color: $principal !important;

            &:after{
                right: 10px !important;
                transition: 0.5s !important;
            }

            &::before{
                width: 2px !important;
                height: 20px !important;
                position: absolute !important;
                content: "" !important;
                background: $principal !important;
                left: 0 !important;
                top: calc(50% - 10px) !important;
                @include sm{
                    display: none !important;
                }
            }
            .sy-nav-ico{
                [class^="icon-"]{
                    color: $principal !important;
                }
            }
        }
    }

    &:hover{

        .sy-link{
            .sy-nav-ico{
                [class^="icon-"]{
                    color: $principal !important;
                }
            }
        }
    }

    .sy-link {
        display: block !important;
        padding: 10px 20px !important;
        color: $text-color !important;
        font-size: 14px !important;

        &:hover {
            color: $principal !important;
            text-decoration: none !important;
        }
        .sy-nav-ico{
            margin:0 15px 0 0 !important;
            display: inline-block !important;
            font-size: rem(20px) !important;

            [class^="icon-"]{
                padding: rem(0 5px) !important;
                color: $gray4 !important;
            }
            img{
                width: 30px !important;
                height: auto !important;
            }
            i{
                font-size: 16px !important;
                margin: 0 auto !important;
            }
            @include sm{
                width: 100% !important;
                margin: 0 !important;
                display: inline-flex !important;
            }
        }
        @include sm{
            padding: 15px 5px !important;
            text-align: center !important;
            font-size: 12px !important;
        }
        @include xxs{
            font-size: 11px !important;
        }
    }
    @include sm{
        width: 16.66% !important;
        display: table-cell !important;
    }
}
.sy-item:nth-child(1).active ~ .bg-navi,.sy-item:nth-child(1):hover ~ .bg-navi {
    top: -9px !important;
    @include sm{
        left: 0 !important;
    }
}



.sy-item:nth-child(2).active ~ .bg-navi,.sy-item:nth-child(2):hover ~ .bg-navi{
    top: 40px !important;
    @include sm{
        top: -9px !important;
        left: 16.66% !important;
    }
}

.sy-item:nth-child(2).active .bg-navi,.sy-item:nth-child(1):hover ~ .bg-navi{
    top: -9px !important;
    @include sm{
        left: 0 !important;
    }
}


.sy-item:nth-child(3).active ~ .bg-navi,.sy-item:nth-child(3):hover ~ .bg-navi {
    top: 90px !important;
    @include sm{
        top: -9px !important;
        left: 33.32% !important;
    }
}

.sy-item:nth-child(3).active  .bg-navi,.sy-item:nth-child(2):hover ~ .bg-navi{
    top: 40px !important;
    @include sm{
        top: -9px !important;
        left: 16.66% !important;
    }
}

.sy-item:nth-child(3).active .bg-navi,.sy-item:nth-child(1):hover ~ .bg-navi{
    top: -9px !important;
    @include sm{
        left: 0 !important;
    }
}




.sy-item:nth-child(4).active ~ .bg-navi,.sy-item:nth-child(4):hover ~ .bg-navi {
    top: 140px !important;
    @include sm{
        top: -9px !important;
        left: 49.98% !important;
    }
}

.sy-item:nth-child(4).active .bg-navi,.sy-item:nth-child(3):hover ~ .bg-navi {
    top: 90px !important;
    @include sm{
        top: -9px !important;
        left: 33.32% !important;
    }
}

.sy-item:nth-child(4).active  .bg-navi,.sy-item:nth-child(2):hover ~ .bg-navi{
    top: 40px !important;
    @include sm{
        top: -9px !important;
        left: 16.66% !important;
    }
}


.sy-item:nth-child(4).active  .bg-navi,.sy-item:nth-child(1):hover ~ .bg-navi {
    top: -9px !important;
    @include sm{
        left: 0 !important;
    }
}


.sy-item:nth-child(5).active ~ .bg-navi,.sy-item:nth-child(5):hover ~ .bg-navi {
    top: 190px !important;
    @include sm{
        top: -9px !important;
        left: 66.64% !important;
    }
}


.sy-item:nth-child(5).active .bg-navi,.sy-item:nth-child(4):hover ~ .bg-navi {
    top: 140px !important;
    @include sm{
        top: -9px !important;
        left: 49.98% !important;
    }
}


.sy-item:nth-child(5).active .bg-navi,.sy-item:nth-child(3):hover ~ .bg-navi {
    top: 90px !important;
    @include sm{
        top: -9px !important;
        left: 33.32% !important;
    }
}


.sy-item:nth-child(5).active  .bg-navi,.sy-item:nth-child(2):hover ~ .bg-navi{
    top: 40px !important;
    @include sm{
        top: -9px !important;
        left: 16.66% !important;
    }
}


.sy-item:nth-child(5).active  .bg-navi,.sy-item:nth-child(1):hover ~ .bg-navi {
    top: -9px !important;
    @include sm{
        left: 0 !important;
    }
}

.sy-item:nth-child(6).active ~ .bg-navi,.sy-item:nth-child(6):hover ~ .bg-navi {
    top: 240px !important;
    @include sm{
        top: -9px !important;
        left: 83.3% !important;
    }
}

.sy-item:nth-child(6).active  .bg-navi,.sy-item:nth-child(5):hover ~ .bg-navi {
    top: 190px !important;
    @include sm{
        top: -9px !important;
        left: 66.64% !important;
    }
}

.sy-item:nth-child(6).active  .bg-navi,.sy-item:nth-child(4):hover ~ .bg-navi {
    top: 140px !important;
    @include sm{
        top: -9px !important;
        left: 49.98% !important;
    }
}

.sy-item:nth-child(6).active  .bg-navi,.sy-item:nth-child(3):hover ~ .bg-navi {
    top: 90px !important;
    @include sm{
        top: -9px !important;
        left: 33.32% !important;
    }
}

.sy-item:nth-child(6).active  .bg-navi,.sy-item:nth-child(2):hover ~ .bg-navi{
    top: 40px !important;
    @include sm{
        top: -9px !important;
        left: 16.66% !important;
    }
}


.sy-item:nth-child(6).active  .bg-navi,.sy-item:nth-child(1):hover ~ .bg-navi {
    top: -9px !important;
    @include sm{
        left: 0 !important;
    }
}



/*fondo de menu, la curva*/
.bg-navi{
    width: 65px;
    height: 150px;
    right: 0;
    top: 0;
    position: absolute;
    transition: 0.5s; 
    overflow: hidden;
    background: #FFFFFF;
    z-index: 2;

    @include sm{
        left: 0;
    }

    &:before{
        width: 0.45em;
        height: 0.45em;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: $principal;
        content:"";
        display: inline-block;
        position: absolute;
         top: calc(50% - 1px);
        right: 15px;
        transform: rotate(-135deg);
        transition: 0.5s;
        @include sm{
            top: 10px;
            right: calc(50% - 3px);
            transform: rotate(-225deg);
        }
        @include xxs{
            top: 7px;
            width: 0.35em;
            height: 0.35em;
            right: calc(50% - 1px);
            transform: rotate(-225deg);
        }
    }
    .bg-navc{
        width: 50px !important;
        &.web{
            display: block !important;
            @include sm{
                display:none !important;
            }
        }
        &.mobile{
            width: 100% !important;
            display: none !important;
            @include sm{
                display: block !important;
                margin: 9px 0 0 0 !important;
            }
        }
    }
    img{
        filter: drop-shadow(1px 0 5px #d6e6ed) !important;
        @include sm{
            transform: scaleX(1.08) !important;
        }
    }
    @include sm{
        width: 16.66% !important;
        height: 50px !important;
    }
}

/*contenido*/
.sy-main {
    -ms-flex-order: 1;
    order: 1;
    padding: 30px;
    @include sm{
        padding: 40px 40px 90px 40px;
    }
}

