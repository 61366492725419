.img-space {
    border: 1px solid #B6B4B4;
    border-radius: 200px;
    width: 40px;
    height: 40px;
    padding: 4px;
}

.modal-cuadro {
    width: 100%;
    height: 50%;
}