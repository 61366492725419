@import '../../../../assets/sass/mixins';

/*ITEMS DE CARDPAYMENTS*/
.card-item {
    padding: 10px;
    border-bottom: 1px solid rgb(223, 223, 223);
}

/*MODAL*/

.cuadro-modal {
    margin-top: 160px;
    text-align: center;
    padding: 20px;
}

.titulo-modal {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #565656;
}

.cuerpo-modal {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #565656;
    margin-bottom: 30px;
}

.link-payment {
    color: #00cccb;
    text-decoration: underline;
    cursor: pointer;
}

.badge-principal {
    background-color: #f5f5f5;
    border-radius: 20px;
    color: #565656;
    font-size: 14px;
    font-weight: 400 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    padding: 10px;
    padding-right: 4rem;
    padding-left: 4rem;
     @include lg {
          padding-right: 2rem;
          padding-left: 2rem;
     }

}

.badge-declined {
    box-shadow: 0 3px 5px 0 rgba(190, 209, 209, 0.45);
    background-color: #fd5c5c;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    padding: 10px;
    padding-right: 4rem;
    padding-left: 4rem;
     @include lg {
         padding-right: 2rem;
         padding-left: 2rem;
     }
}

.badge-proccess {
    box-shadow: 0 3px 5px 0 rgba(190, 209, 209, 0.45);
    background-color: #fecf33;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    padding: 10px;
    padding-right: 4rem;
    padding-left: 4rem;
     @include lg {
         padding-right: 2rem;
         padding-left: 2rem;
     }
}

.texto-rojo {
    color: red;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    font-size: 12px;
}

.toggle-box {
    margin-top: 28px;
    margin-right: 10px;
    padding-left: 10px;
}

.debit-box {
    margin: 20px;
    padding-top: 15px;
}

.debit-toogle {
    margin: 20px;
    padding-top: 5px;
}

.boton-agregar-mp {
    width: 35px;
    height: 35px;
    padding: 0 0 35px 0;
    font-size: 25px;
    font-weight: 600;
}

.card-container {
    min-width: 50%;
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
}
